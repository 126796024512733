import React, { ChangeEvent } from "react";

interface SelectProps extends React.HTMLProps<HTMLSelectElement> {
    label?: string;
    options?: { title: string; value: string }[];
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    id?: string;
    children?: any;
    value?: any;
    required?: boolean;
    margin?: string;
    autoComplete?: string;
}

const Select: React.FC<SelectProps> = (props) => {
    const {
        label,
        options,
        onChange,
        id,
        children,
        value,
        required,
        autoComplete,
        defaultValue,
    } = props;

    return (
        <div>
            {label && (
                <label
                    htmlFor={id}
                    className="block text-sm font-medium text-gray-700"
                >
                    {label}
                    {required && <span className="text-red-500">*</span>}
                </label>
            )}
            <select
                id={id}
                onChange={(event) => onChange(event)}
                value={value}
                autoComplete={autoComplete}
                required={required || false}
                defaultValue={defaultValue}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
            >
                {options
                    ? options.map((option) => (
                          <option value={option.value} key={option.value}>
                              {option.title}
                          </option>
                      ))
                    : children}
            </select>
        </div>
    );
};

export default Select;
