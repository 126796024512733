import { ProductInterface, ProductType } from "../interfaces/product.interface";
import { ProductSerialCardPriceInterface } from "../interfaces/product-serialcard-price.interface";

export const hasMinAndStep = (product: ProductInterface): boolean => {
    if (product.availability) {
        return product.availability.step && product.availability.min
            ? true
            : false;
    }
    return false;
};

export const isSerialCard = (product: ProductInterface): boolean => {
    return product.type === ProductType.SERIAL_CARD;
};

export const hasValidSteps = (product: ProductInterface): boolean => {
    return (
        product.availability.step! > 1 &&
        product.availability.min! % product.availability.step! === 0
    );
};

export const isValidSerialCardPackage = (product: ProductInterface): boolean => {
    return (
        hasMinAndStep(product) &&
        isSerialCard(product) &&
        hasValidSteps(product)
    );
};

export const solveSerialCardPackagePrice = (
    product: ProductInterface
): ProductSerialCardPriceInterface => {
    const { price, availability } = product;
    let packagePrice = {
        serial_price: price,
        step: 1,
        validPackage: false,
    };
    if (isValidSerialCardPackage(product)) {
        packagePrice.serial_price = price * availability.step! || 0;
        packagePrice.step = availability.step!;
        packagePrice.validPackage = true;
    }
    return packagePrice;
};

const solveSerialCardPackagePriceSimple = (
    product: ProductInterface
): number => {
    const { price, availability } = product;
    if (!isSerialCard || !hasMinAndStep(product)) {
        return price;
    }
    if (!hasValidSteps(product)) {
        return price;
    }
    //Esitetään pakettimuodossa vain, jos kerranaiset ovat yleisesti oikein
    return price * availability.step! || 0;
};