import React from "react";
import { useTranslation } from "react-i18next";
import { TranslationKeys } from "../../interfaces/translation-keys.enum";
import { ProductInterface } from "../../interfaces/product.interface";
import { priceHelper } from "../../helpers/price.helper";
import { solveSerialCardPackagePrice, isSerialCard} from "../../helpers/serialcard.helper";

interface ProductPriceComponentProps {
    product: ProductInterface;
}

const ProductPrice: React.FC<ProductPriceComponentProps> = (props) => {
    const { product } = props;
    const { t } = useTranslation();

    if(!isSerialCard(product)) {
        return <>{priceHelper(product.price)}</>;
    }

    const productPricePackage = solveSerialCardPackagePrice(product);
    const productSerialPrice = priceHelper(productPricePackage.serial_price);

    if (!productPricePackage.validPackage) {
        return <>{productSerialPrice}</>;
    }

    const productPackageString = (
        <>
            {t(TranslationKeys.PRODUCT_SERIALCARD_PACKAGE_PRICEINFO, {amount: productPricePackage.step})}
        </>
    );

    return (
        <>
            {productSerialPrice} <span className="inline-block">{productPackageString}</span>
        </>
    );
};

export default ProductPrice;
