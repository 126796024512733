export interface ProductDropdownQuantityInterface {
    min: number,
    max: number,
    amount: number,
    type: ProductDropdownType,
}

export enum ProductDropdownType {
    NORMAL,
    PACKAGE_EVEN,
    PACKAGE_UNEVEN,
}