import React, {
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
} from "react";

const Modal = React.forwardRef((props: any, ref: any) => {
    const wrapperRef = useRef<any>(null);
    const [open, setOpen] = React.useState(false);

    useImperativeHandle(ref, () => ({
        handleOpen() {
            setOpen(true);
        },
    }));

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside, false);
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
            document.removeEventListener(
                "mousedown",
                handleClickOutside,
                false
            );
        };
    }, [escFunction]);

    const handleClickOutside = (event: any) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setOpen(false);
        }
    };

    return (
        <div
            className={
                (open ? "modal-active" : "opacity-0 pointer-events-none") +
                " modal fixed w-full min-h-screen top-0 left-0 flex items-center justify-center z-50"
            }
        >
            <div className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50 z-30" />
            <div
                ref={wrapperRef}
                className="modal-container bg-white mx-auto rounded shadow-lg z-50 overflow-y-auto"
            >
                <div
                    className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50"
                    onClick={() => setOpen(false)}
                >
                    <span className="text-sm text-black md:text-white">
                        Sulje
                    </span>
                </div>
                <div
                    className="modal-content py-4 text-left px-6 my-4"
                    ref={ref}
                >
                    {props.title && (
                        <div className="flex justify-between items-center pb-3">
                            <p className="text-2xl font-semibold">
                                {props.title}
                            </p>
                        </div>
                    )}
                    {props.children}
                    {props.close && (
                        <button
                            className="btn bg-primary-600 mt-4"
                            onClick={() => {
                                setOpen(false);
                                props.closeAction && props.closeAction();
                            }}
                        >
                            Sulje
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
});

export default Modal;
