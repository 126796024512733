import React from "react";
import { useTranslation } from "react-i18next";
import { ProductInterface } from "../../interfaces/product.interface";
import { TranslationKeys } from "../../interfaces/translation-keys.enum";
import { ProductDropdownQuantityInterface, ProductDropdownType } from "../../interfaces/product-dropdown-quantity.interface";
import { isValidSerialCardPackage } from "../../helpers/serialcard.helper";

const ProductAmountOptions = (product: ProductInterface): JSX.Element[] => {
    const amounts: JSX.Element[] = [];
    const { t } = useTranslation();

    if (!product.availability || !product.availability.available)
        return amounts;
    if (
        product.availability.max === undefined ||
        product.availability.step === undefined ||
        product.availability.min === undefined
    )
        return amounts;
    // Dropdown datan buildaus
    amounts.push(
        <option value={0} key={0}>
            0
        </option>
    );

    const step: ProductDropdownQuantityInterface = {
        max: product.availability.max,
        min: product.availability.min,
        amount: product.availability.step ?? 1,
        type: ProductDropdownType.NORMAL,
    };

    if (isValidSerialCardPackage(product)) {
        step.max = product.availability.max / product.availability.step;
        step.type = ProductDropdownType.PACKAGE_EVEN;
    } else if(
        product.availability.step > 1
    ) {
        step.max = ((product.availability.max-step.min) / product.availability.step);
        step.type = ProductDropdownType.PACKAGE_UNEVEN;
    }

    for (let i = 0; i < step.max; i++) {
        const { amount } = step;
        const total = step.min + i*amount;
        const times = i+1;

        amounts.push(
            <option value={total} key={total}>
                {step.type === ProductDropdownType.PACKAGE_EVEN
                    ? t(TranslationKeys.PRODUCT_SERIALCARD_PACKAGE_DROPDOWN, {
                        times,
                        amount,
                        total,
                    })
                    : total}
            </option>
        );
    }
    return amounts;
};

export default ProductAmountOptions;
