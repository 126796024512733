import { TranslatableStringInterface } from "./translatable-string.interface";

export enum OptionQuestionType {
    TEXT = "text",
    CHOICE = "choice",
}

export interface OptionInterface {
    title: TranslatableStringInterface;
    options: {
        title: TranslatableStringInterface;
    }[];
    required: boolean;
    mode: string;
    question_type: OptionQuestionType;
}
